import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { GetReportDataResponse } from "src/app/entity/get-report-data-response";
import { ReportCard } from "src/app/entity/report-card";
import { ReportSite } from "src/app/entity/report-site";
import { environment } from "src/environments/environment";
import { MeterCardListCommonService } from "./meter-card-list-common.service";
import { ReportPartitionsTenant } from "src/app/entity/report-partitions-tenant";
import { ReportTenant } from "src/app/entity/report-tenant";
import { Meter } from "src/app/entity/meter";
import { ReportMeters } from "src/app/entity/report-meters";
import { LastMonthValueConfirmation } from "src/app/entity/last-month-value-confirmation";

/**
 * レポート未確定時のデータ取得クラス
 */
@Injectable()
export class MeterCardListPendingService {
  constructor(
    private httpClient: HttpClient,
    private meterCardListCommonService: MeterCardListCommonService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  /**
   * 新規レポートデータからカード表示データを作成する
   * @param apiToken(string): APIトークン
   * @param floorId(string): 現在のフロアID
   * @param siteId(string): 現在のSiteID
   * @param selectedMonth(string): 現在の年月（yyyyMM）
   * @param lastMonthConfirmDate(string): 前月のレポート確定日
   * @returns 作成した区画リスト: Promise<ReportCard[]>
   */
  async createPartitionsArrayForReportData(
    apiToken: string,
    floorId: string,
    siteId: string,
    selectedMonth: string,
    lastMonthConfirmDate: string
  ): Promise<ReportCard[]> {
    let result: ReportCard[] = [];

    try {
      // APIのコール
      const reportSite = await this.getReportData(
        apiToken,
        siteId,
        selectedMonth,
        lastMonthConfirmDate
      );

      // 現在のフロア情報を取得
      const currentFloor = reportSite.floors.find(
        (value) => value.id == floorId
      );

      currentFloor.partitions.forEach((partition) => {
        // 対象でなければスキップ
        if (!this.meterCardListCommonService.shouldCreateCardData(partition)) {
          return; // continueする
        }

        partition.partitions_tenants.forEach((partitionsTenants) => {
          // 1行分を作成
          const meters = this.createReportCardRow(
            partitionsTenants.tenant.id,
            partition.meters,
            partition.partitions_tenants
          );

          const row = new ReportCard();
          row.partition_id = partition.id;
          row.partition_name = partition.name;
          row.tenant_id = partitionsTenants.tenant.id;
          row.tenant_name = partitionsTenants.tenant.name;
          row.meters = meters;

          result.push(row);
        });
      });
    } catch (error) {
      throw error;
    }
    return result;
  }

  /**
   * APIからデータを取得する
   * @param apiToken(string): APIトークン
   * @param siteId(string): 現在のSiteID
   * @param selectedMonth(string): 現在の年月（yyyyMM）
   * @param lastMonthConfirmDate(string): 前月のレポート確定日（yyyyMMdd）
   * @returns APIの結果のSite以下: Promise<ReportSite>
   */
  private async getReportData(
    apiToken: string,
    siteId: string,
    selectedMonth: string,
    lastMonthConfirmDate: string
  ): Promise<ReportSite> {
    const url = `${environment.apiUrl}/sites/${siteId}/partitions/meters/${selectedMonth}/value_confirmations/${lastMonthConfirmDate}`;

    try {
      const response = await this.httpClient
        .get<GetReportDataResponse>(url, {
          headers: new HttpHeaders({
            Authorization: apiToken,
          }),
        })
        .toPromise();

      return response.result.site;
    } catch (error) {
      throw error;
    }
  }

  /**
   * カード表示用データのメーター部分を作成する
   * @param tenantId(string): テナントID
   * @param meters(ReportMeters[]): メーターリスト
   * @param partitionsTenants(ReportPartitionsTenant[]): API結果のpartitions_tenants以下
   * @returns カード表示用データのメーター部分: Meter[]
   */
  private createReportCardRow(
    tenantId: string,
    meters: ReportMeters[],
    partitionsTenants: ReportPartitionsTenant[]
  ): Meter[] {
    // metersの型変換
    let result: Meter[] = [];

    meters.forEach((meter) => {
      // 先月確定値の作成
      const lastMonthValueConfirmation = this.getLastMonthValueConfirmation(
        meter,
        tenantId,
        partitionsTenants
      );

      // 今月確定値の作成
      const valueDetections =
        this.meterCardListCommonService.getValueDetections(tenantId, meter);

      // 終了値
      const closedValue = this.judgeClosedValue(
        tenantId,
        meter,
        partitionsTenants
      );

      result.push(
        new Meter(
          meter.id,
          meter.name,
          meter.label,
          meter.number_of_digits,
          meter.decimal_point_position.toString(),
          closedValue,
          meter.is_reviewed == "1",
          meter.created_at,
          meter.updated_at,
          meter.unit,
          lastMonthValueConfirmation,
          closedValue == "" ? valueDetections : [] // 当月確定値は終了値が存在する場合表示させない
        )
      );
    });
    return result;
  }

  /**
   * 自分が旧テナントかどうかの判定
   * @param currentTenantId(string): 確認対象のテナントID
   * @param partitionsTenants(ReportPartitionsTenant[]): 元データのpartitions_tenants
   * @returns 旧テナントかどうか: boolean
   */
  private isOldTenant(
    currentTenantId: string,
    partitionsTenants: ReportPartitionsTenant[]
  ): boolean {
    // テナントが1つしかない場合
    if (partitionsTenants.length == 0) {
      return false;
    }

    // 作成日時の昇順でソート
    // tenantsの作成日時だと、区画拡張の時に狂うので、partitions_tenantsでソートする
    partitionsTenants.sort(
      (a, b) => Number(new Date(a.contracted_at)) - Number(new Date(b.contracted_at))
    );

    // tenantだけを抽出
    let tenants: ReportTenant[] = [];
    partitionsTenants.forEach((partitionsTenant) => {
      tenants.push(partitionsTenant.tenant);
    });

    // 現在のテナントIDのインデックスを特定
    const currentTenantIndex = tenants.findIndex(
      (tenant) => tenant.id == currentTenantId
    );

    // テナントの数−1であれば（インデックスが最大であれば）false、それ以外はtrue
    return currentTenantIndex != tenants.length - 1;
  }

  /**
   * 自分が旧テナントを持っているか
   * @param currentTenantId(string): 確認対象のテナントID
   * @param partitionsTenants(ReportPartitionsTenant[]): 元データのpartitions_tenants
   * @returns 旧テナントを持っているか: boolean
   */
  private hasOldTenant(
    currentTenantId: string,
    partitionsTenants: ReportPartitionsTenant[]
  ): boolean {
    // テナントが1つしかない場合
    if (partitionsTenants.length == 0) {
      return false;
    }

    // 作成日時の昇順でソート
    // tenantsの作成日時だと、区画拡張の時に狂うので、partitions_tenantsでソートする
    partitionsTenants.sort(
      (a, b) => Number(new Date(a.contracted_at)) - Number(new Date(b.contracted_at))
    );

    // tenantだけを抽出
    let tenants: ReportTenant[] = [];
    partitionsTenants.forEach((partitionsTenant) => {
      tenants.push(partitionsTenant.tenant);
    });

    // 現在のテナントIDのインデックスを特定
    const currentTenantIndex = tenants.findIndex(
      (tenant) => tenant.id == currentTenantId
    );

    // インデックスが0（一番古いデータ）でなければtrue
    return currentTenantIndex != 0;
  }

  /**
   * 旧テナントIDを特定する
   * @param currentTenantId(string): 確認対象のテナントID
   * @param partitionsTenants(ReportPartitionsTenant[]): API結果のpartitions_tenants以下
   * @returns 旧テナントID: string
   * - 旧テナントがない、自分が旧テナントな場合などは空文字
   */
  private getOldTenantId(
    currentTenantId: string,
    partitionsTenants: ReportPartitionsTenant[]
  ): string {
    // テナントが1つしかない場合
    if (partitionsTenants.length == 0) {
      return "";
    }

    // 作成日時の昇順でソート
    // tenantsの作成日時だと、区画拡張の時に狂うので、partitions_tenantsでソートする
    partitionsTenants.sort(
      (a, b) => Number(new Date(a.contracted_at)) - Number(new Date(b.contracted_at))
    );

    // 自分が旧テナントの場合
    if (this.isOldTenant(currentTenantId, partitionsTenants)) {
      return "";
    }

    // tenantだけを抽出
    let tenants: ReportTenant[] = [];
    partitionsTenants.forEach((partitionsTenant) => {
      tenants.push(partitionsTenant.tenant);
    });

    // 現在のテナントIDのインデックスを特定
    const currentTenantIndex = tenants.findIndex(
      (tenant) => tenant.id == currentTenantId
    );

    // 現在のテナントIDのインデックス-1のテナントIDを返す
    if (currentTenantIndex == 0) {
      return "";
    } else {
      return tenants[currentTenantIndex - 1].id;
    }
  }

  /**
   * テナント登録時のメーター初期値を取得
   * @param tenantId(string): テナントID
   * @param meters(ReportMeters): メーター
   * @param partitionsTenants(ReportPartitionsTenant[]): API結果のpartitions_tenants以下
   * @returns 取得した初期値: string
   * - メーターに適した値があれば取得、なければ空欄
   */
  private getMeterValueAtTenantRegistration(
    tenantId: string,
    meter: ReportMeters,
    partitionsTenants: ReportPartitionsTenant[]
  ): string {
    let result = "";

    partitionsTenants.some((partitionsTenant) => {
      if (
        partitionsTenant.tenant &&
        partitionsTenant.tenant.initial_value &&
        tenantId == partitionsTenant.tenant.id
      ) {
        // JSONにパース
        const initialValues = JSON.parse(partitionsTenant.tenant.initial_value);
        const initialTenantMeterInfo = initialValues.find(
          (value) => value.meterLabel == meter.label
        );

        // 上記で見つかったらそのメーター値を返却値とする
        if (initialTenantMeterInfo) {
          result = initialTenantMeterInfo.meterValue;
          return true;
        } else {
          return false;
        }
      }
    });

    return result;
  }

  /**
   * 前月確定値のオブジェクトを作成する
   * 1. 自分が旧テナントでない（isOldTenant=false）&旧テナントを持っている（hasOldTenant=true）：*テナント登録時の初期値*
   * 2. 1の条件に当てはまるが、テナント初期値がない（テナントの区画拡張）：*旧テナントの当月確定値（=旧テナントの解約時の値）*
   * 3. 上記以外：*前回確定値*
   * 4. 前回確定値がない場合：*メーターの初期値*
   *
   * @param meter(ReportMeters): 対象のメーター
   * @param tenantId(string): 対象のテナントID
   * @param partitionsTenants(ReportPartitionsTenant[]): API結果のpartitions_tenants以下
   *
   * @returns 前月確定値情報: LastMonthValueConfirmation
   */
  private getLastMonthValueConfirmation(
    meter: ReportMeters,
    tenantId: string,
    partitionsTenants: ReportPartitionsTenant[]
  ): LastMonthValueConfirmation {
    // 前月確定値は降順でソート
    const lastMonthValueDetections = meter.last_month_value_detections;
    lastMonthValueDetections.sort(
      (a, b) => Number(new Date(b.created_at)) - Number(new Date(a.created_at))
    );

    // フラグ設定
    const isOldTenant = this.isOldTenant(tenantId, partitionsTenants);
    const hasOldTenant = this.hasOldTenant(tenantId, partitionsTenants);

    // テナント登録時のメーター値
    const tenantInitialValue = this.getMeterValueAtTenantRegistration(
      tenantId,
      meter,
      partitionsTenants
    );

    // 返却値
    // 初期値（No.4該当）はメーター初期値 or テナント登録時の初期値で設定（他はダミー）
    let result: LastMonthValueConfirmation;
    if (!isOldTenant && hasOldTenant && tenantInitialValue !== "") {
      result = new LastMonthValueConfirmation("", tenantInitialValue, "", "");
    } else {
      result = new LastMonthValueConfirmation("", meter.initial_value, "", "");
    }

    // 今のテナントIDに合う先月確定値のオブジェクトを作成
    lastMonthValueDetections.some((detection) => {
      // 通常の前回確定値
      if (detection.tenant_id == tenantId && detection.value_confirmation) {
        result = new LastMonthValueConfirmation(
          detection.value_confirmation.id,
          detection.value_confirmation.confirmed_value,
          detection.value_confirmation.confirmation_channel,
          detection.value_confirmation.created_at
        );
      }
    });

    // No.1,2に該当する場合は確定値を上書きする
    const oldTenantId = this.getOldTenantId(tenantId, partitionsTenants);

    // 自分が旧テナントでない、かつ、旧テナントを持っている
    if (!isOldTenant && hasOldTenant) {

      // テナントの初期値が存在する
      if (tenantInitialValue != "") {
        // No.1該当
        result = new LastMonthValueConfirmation(
          result.id,
          tenantInitialValue,
          result.confirmation_channel,
          result.created_at
        );
      } else {
        // No.2該当
        // 旧テナントの当月確定値を取得
        const oldTenantValue = this.judgeClosedValue(
          oldTenantId,
          meter,
          partitionsTenants
        );
        result = new LastMonthValueConfirmation(
          result.id,
          oldTenantValue,
          result.confirmation_channel,
          result.created_at
        );
      }
    }

    return result;
  }

  /**
   * 解約時の検針値を決定する
   * 1. メーターの解約値があればそちらを優先
   * 2. 上記がなければ、該当するテナントの解約時の値を取得
   *
   * @param tenantId(string): テナントID
   * @param meters(ReportMeters): メーター
   * @param partitionsTenants(ReportPartitionsTenant[]): API結果のpartitions_tenants以下
   * @returns 判定後の解約時の検針値: string
   * - 解約がなければ空文字
   */
  private judgeClosedValue(
    tenantId: string,
    meter: ReportMeters,
    partitionsTenants: ReportPartitionsTenant[]
  ): string {
    let result = "";

    // メーター情報にclosed_valueがあればそちらを優先
    if (meter.closed_value) {
      return meter.closed_value;
    }

    // 上記でなければ、テナントのclosed_valueを検索
    partitionsTenants.some((partitionsTenant) => {
      if (
        partitionsTenant.tenant &&
        partitionsTenant.tenant.closed_value &&
        tenantId == partitionsTenant.tenant.id
      ) {
        // JSONにパース
        const closedValues = JSON.parse(partitionsTenant.tenant.closed_value);
        const closedTenantMeterInfo = closedValues.find(
          (value) => value.meterLabel == meter.label
        );

        // 上記で見つかったらそのメーター値をclosed_valueとする
        if (closedTenantMeterInfo) {
          result = closedTenantMeterInfo.meterValue;
          return true;
        } else {
          return false;
        }
      }
    });

    return result;
  }
}
