/**
 * Alertに対するメッセージ定数
 * - オブジェクトリテラル
 * - プレースホルダーはキー指定式
 *   - 例）"こんにちは、{name}さん"
 */
export const AlertMessage = {
  // 汎用
  UNEXPECTED_ERROR_MESSAGE: "予期せぬエラーが起こりました。",
  NET_ERROR_MESSAGE: "ネットワーク接続を確認してください。",

  // ログイン・ログアウト関連
  LOGIN_FAILED_MESSAGE: "ユーザーID、またはパスワードが間違っています。", // パスワードミスなどによるログイン失敗時の共通メッセージ、脆弱性対策のためにも統一させる
  ACCOUNT_LOCKED_MESSAGE: "アカウントがロックされました。\n管理者までお問合せください。",
  SESSION_BREAK_MESSAGE: "セッションが切れています。\n再度ログインしてください。",
  LOGIN_ERROR_MESSAGE: "ログインに失敗しました。",
  NOTFOUND_ACCOUNT_INFO_MESSAGE: "ログイン情報が取得できませんでした。\n再度ログインしてください。",

  // 施設（site）関連
  NOTFOUND_SITE_INFO_MESSAGE: "施設情報が取得できませんでした。",
};
