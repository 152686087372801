import { Meter } from "./meter";

/**
 * カード表示レポート用データクラス
 */
export class ReportCard {
    partition_id: string;
    partition_name: string;
    tenant_id: string;
    tenant_name: string;
    meters: Meter[];
}