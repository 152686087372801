import { formatDate } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { GetReportListReportList } from "src/app/entity/get-report-list-report-list";
import { GetReportListResponse } from "src/app/entity/get-report-list-response";
import { ReportMeters } from "src/app/entity/report-meters";
import { ReportPartitions } from "src/app/entity/report-partitions";
import { ValueDetection } from "src/app/entity/value-detection";
import { environment } from "src/environments/environment";

/**
 * サービスクラスの共通処理
 */
@Injectable()
export class MeterCardListCommonService {
  constructor(
    private httpClient: HttpClient,
    @Inject(LOCALE_ID) private locale: string
  ){

  }

  /**
   * カード表示用データの対象かどうかのチェック
   * @param partition(ReportPartitions): API結果のpartitions以下
   * @returns 対象かどうかの結果: boolean
   */
  shouldCreateCardData(partition: ReportPartitions): boolean {
    // メーター情報が1つでもあれば対象
    return partition.meters.length > 0;
  }

  /**
   * レポートリストを取得
   * @param apiToken(string): APIトークン
   * @param siteId(string): 現在のSiteID
   */
  async getReportList(apiToken: string, siteId: string): Promise<GetReportListReportList[]> {
    const url = `${environment.apiUrl}/report_list/${siteId}`;

    try {
      const response = await this.httpClient
        .get<GetReportListResponse>(url, {
          headers: new HttpHeaders({
            Authorization: apiToken,
          }),
        })
        .toPromise();

      return response.result.report_list;
    } catch (error) {
      throw error;
    }
  }

  /**
   * レポートリストから前月の確定日を取得
   * @param reportList(GetReportListReportList[]): レポートリスト
   * @param selectedMonth(string): 現在の年月（yyyyMM）
   * @returns 前月の確定日: string(yyyyMMdd)
   */
  getLastMonthConfirmDate(
    reportList: GetReportListReportList[],
    selectedMonth: string
  ): string {
    // 選択した年月の前月の日付型変数を用意
    // JSやTSのDateのMonth部分は月ではなく、月に該当するIndex（0始まり）なので−1した後に−1をsetする
    let lastMonthDate = new Date(Number(selectedMonth.substring(0, 4)), Number(selectedMonth.substring(4)) - 1, 1);
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

    // 返却値、初期値は選択した年月の前月月初にする
    let result = formatDate(lastMonthDate, "yyyyMMdd", this.locale);

    //前の月の確定日を抽出
    if (reportList.length > 0) {
      const lastMonthReportInfo = reportList.find(
        (element) => element.report_month == formatDate(lastMonthDate, "yyyyMM", this.locale)
      );

      if (lastMonthReportInfo) {
        result =
          lastMonthReportInfo.confirmed_report_created_at == null ||
          lastMonthReportInfo.confirmed_report_created_at == undefined
            ? result
            : formatDate(lastMonthReportInfo.confirmed_report_created_at, "yyyyMMdd", this.locale);
      }
    }
    return result;
  }

  /**
   * 今月確定値のオブジェクトを作成する
   * @param tenantId(string): テナントID
   * @param meter(ReportMeters): メーター
   * @returns 今月確定値: ValueDetection[]
   * - カード表示側では先頭しか表示しないので、1つしか作らなくてOK
   */
  getValueDetections(tenantId: string, meter: ReportMeters): ValueDetection[] {
    let result: ValueDetection[] = [];

    // value_detectionsが無ければ空のリストを返却
    if (meter.value_detections.length == 0){
      return [];
    }

    // 確定値は降順でソート
    const valueDetections = meter.value_detections;
    valueDetections.sort((a, b) => Number(new Date(b.created_at)) - Number(new Date(a.created_at)));

    valueDetections.some(detection => {
      if (detection.tenant_id == tenantId && detection.value_confirmation){
          result.push( new ValueDetection(
            detection.id,
            detection.detected_value,
            detection.picture_url,
            detection.picture_date,
            detection.tenant_id,
            detection.created_at,
            detection.value_confirmation
          ));
        return true;
      } else {
        return false;
      }
    });

    return result;
  }


}
