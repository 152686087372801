import { LastMonthValueConfirmation } from './last-month-value-confirmation';
import { Unit } from './unit';
import { ValueDetection } from './value-detection';


export class Meter {
    constructor(
        id: string, name: string, label: string, number_of_digits: string,
        decimal_point_position: string, closed_value: string, is_reviewed: boolean, created_at: string, updated_at: string,
        unit: Unit,last_month_value_confirmation: LastMonthValueConfirmation, value_detections: ValueDetection[]
    ) {
        this.id = id;
        this.name = name;
        this.label = label;
        this.number_of_digits = number_of_digits;
        this.decimal_point_position = decimal_point_position;
        this.closed_value = closed_value;
        this.is_reviewed = is_reviewed;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.unit = unit;
        this.last_month_value_confirmation = last_month_value_confirmation;
        this.value_detections = value_detections;
    }
    id: string;
    name: string;
    label: string;
    number_of_digits: string;
    decimal_point_position: string;
    closed_value: string;
    created_at: string;
    updated_at: string;
    is_reviewed: boolean;
    unit: Unit;
    last_month_value_confirmation: LastMonthValueConfirmation;
    value_detections: ValueDetection[];
}
