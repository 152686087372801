import { ValueDetectionUser } from './value-detection-user';
import { ValueConfirmation } from './value-confirmation';

export class ValueDetection {
    constructor(
        id: string, detected_value: string, picture_url: string, picture_date: string, tenant_id: string,
        created_at: string, value_confirmation: ValueConfirmation
    ) {
        this.id = id;
        this.detected_value = detected_value;
        this.picture_url = picture_url;
        this.picture_date = picture_date; 
        this.tenant_id = tenant_id;
        this.created_at = created_at;
        this.value_confirmation = value_confirmation;
    }

    id: string;
    detected_value: string;
    picture_url: string;
    picture_date: string;
    tenant_id: string;
    created_at: string;
    value_confirmation: ValueConfirmation;
}
