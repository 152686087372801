import { formatDate } from "@angular/common";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { DateAdapter, NativeDateAdapter } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { Const } from "../const/const";
import { CompareDialogData } from "../entity/compare-dialog-data";
import { CorrectDialogData } from "../entity/correct-dialog-data";
import { GetInSiteMeterResponse } from "../entity/get-in-site-meters-response";
import { GetLoginUserResponse } from "../entity/get-login-user-response";
import { MeterInfo } from "../entity/get-meter-info";
import { GetReportDataResponse } from "../entity/get-report-data-response";
import { GetReportListResponse } from "../entity/get-report-list-response";
import { SiteInfo } from "../entity/get-site-info";
import { GetTenantsPartition } from "../entity/get-tenants-partition";
import { GetTenantsResponse } from "../entity/get-tenants-response";
import { LoginUser } from "../entity/login-user";
import { LoginUserSite } from "../entity/login-user-site";
import { LoginUserSiteCompany } from "../entity/login-user-site-company";
import { MessageInfo } from "../entity/message-info";
import { Meter } from "../entity/meter";
import { ReportListInfo } from "../entity/report-list-info";
import { ReportPartitions } from "../entity/report-partitions";
import { ReportRow } from "../entity/report-row";
import { MessageDialogComponent } from "../message-dialog/message-dialog.component";
import { ReportConfirmDialogComponent } from "../report-confirm-dialog/report-confirm-dialog.component";
import { ReportListDialogComponent } from "../report-list-dialog/report-list-dialog.component";
import { ValueCompareDialogComponent } from "../value-compare-dialog/value-compare-dialog.component";
import { ValueCorrectDialogComponent } from "../value-correct-dialog/value-correct-dialog.component";
import { GetReportConfirmedDataResponse } from "../entity/get-confirmed-report-data-response";
import { BigNumber } from "bignumber.js";
import { ReportMeters } from "../entity/report-meters";
import { ReportPartitionsTenant } from "../entity/report-partitions-tenant";
import { ReportTenant } from "../entity/report-tenant";

@Component({
  selector: "app-meter-report",
  templateUrl: "./meter-report.component.html",
  styleUrls: ["./meter-report.component.scss"],
})
export class MeterReportComponent implements OnInit, OnDestroy {
  selectedSite = new UntypedFormControl();
  selectedType = new UntypedFormControl("すべて");
  selectedMonth: number;
  idToken: string;
  typeArray: string[];

  // ログインユーザー取得API関連
  getLoginUserUrl: string;
  getLoginUserResponse: GetLoginUserResponse;
  loginUser: LoginUser;

  // レポートデータ取得API関連
  getReportDataUrl: string;
  reportAPIResponse: GetReportDataResponse;
  reportPartitions: ReportPartitions[];

  // APIレスポンス
  private getReportConfirmedDataResponse: GetReportConfirmedDataResponse;

  // レポート確定
  postConfirmedReportUrl: string;

  // レポート取得
  getReportListUrl: string;

  // メーター情報１件取得API関連
  getOneMeterDataUrl: string;
  meter: Meter;

  // フラグ類
  getReportDataFinished: boolean;
  getUserFinished: boolean;
  canEdit: boolean;
  isConfirmedReport: boolean;
  isAlreadyConfirmed: boolean;
  mjitUserFlag = false;
  adminUserFlag = false;
  isConfirmedDataSorce = false; // データの取得元が確定レポートデータかどうかのフラグ

  // データソース格納用の配列
  reportData: ReportRow[];
  reportDataBackUp: ReportRow[];

  // 列の表示項目を決める配列
  displayedColumns: string[];

  // レポートリスト表示用
  reportList: ReportListInfo[];
  siteArray: LoginUserSite[];

  userTypeSysAdmin = 0;
  userTypeAdmin = 1;
  userTypeUser = 2;
  // 有効切れメーターリスト
  expMeterData: MeterInfo[] = [];
  partitionTenants: GetTenantsPartition[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    public auth: AuthService,
    public dialog: MatDialog,
    dateAdapter: DateAdapter<NativeDateAdapter>,
    @Inject(LOCALE_ID) private locale: string
  ) {
    localStorage.setItem("path", router.url);
    dateAdapter.setLocale("ja");
  }

  ngOnInit() {
    this.loginUser = new LoginUser();
    this.getLoginUserResponse = new GetLoginUserResponse();
    this.reportData = [];
    this.reportDataBackUp = [];
    this.reportList = [];
    this.siteArray = [];
    this.typeArray = ["すべて", "電灯", "動力", "水道", "ガス"];
    this.getUserFinished = false;
    this.getReportDataFinished = false;
    this.selectedSite.disable();
    this.selectedType.disable();
    this.canEdit = false;
    this.isConfirmedReport = false;
    this.displayedColumns = [
      "partition",
      "name",
      "info",
      "label",
      "type",
      "lastMonth",
      "thisMonth",
      "used",
    ];
    this.expMeterData = [];
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
        if (Const.loginUser === null) {
          this.doGetLoginUser();
        } else {
          this.doSetValue();
        }
      } else {
        alert("セッションが切れています。再度ログインしてください。");
        this.onClickLogout();
      }
    });
  }

  doSetValue() {
    this.loginUser = Const.loginUser;
    this.selectedSite = new UntypedFormControl(Const.site_id);
    if (Number(this.loginUser.user_type) === this.userTypeSysAdmin) {
      if (Const.siteInfo.length > 0) {
        this.siteArray = Const.siteInfo;
      } else {
        this.getAllSiteData();
      }
    } else {
      for (const site of this.loginUser.sites) {
        this.siteArray.push(site);
      }
    }
    this.mjitUserFlag = Const.mjitUser;
    this.adminUserFlag = Const.adminUser;
    this.selectedType = new UntypedFormControl("すべて");
    this.loadMeterData();
    this.doGetReportList("make");
  }

  doChangeType() {
    this.selectedType.disable();
    this.selectedSite.disable();
    this.getUserFinished = false;
    this.getReportDataFinished = false;
    this.canEdit = false;
    this.isConfirmedReport = false;
    this.loadMeterData();
    this.doGetReportList("filtered");
  }

  // 施設情報の取得（UserType:0はシステム管理者として登録されている施設を全件取得する。）
  getAllSiteData() {
    Const.siteInfo.splice(0);
    const url = `${environment.apiUrl}/sites/sites_info_all/all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          const jsonStr = JSON.stringify(res);
          const jsonObj = JSON.parse(jsonStr);
          for (const site of jsonObj.result.sites as SiteInfo[]) {
            const siteItem = new LoginUserSite();
            siteItem.id = site.id;
            siteItem.name = site.name;
            siteItem.address = site.address;
            siteItem.updated_at = site.updated_at;
            siteItem.created_at = site.created_at;
            siteItem.company = new LoginUserSiteCompany();
            siteItem.company.id = site.company_id;
            siteItem.company.name = site.company_name;
            Const.siteInfo.push(siteItem);
          }
          this.siteArray = Const.siteInfo;
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("施設情報が取得できませんでした。");
            console.error("Get site data failed");
            console.error(err);
          }
        }
      );
  }

  doChangeSite() {
    this.getReportDataFinished = false;
    this.selectedSite.disable();
    this.selectedType.disable();
    Const.site_id = this.selectedSite.value;
    localStorage.setItem("siteId", this.selectedSite.value);
    this.selectedType = new UntypedFormControl("すべて");
    this.loadMeterData();
    this.doGetReportList("make");
  }

  // レポート一覧の取得処理（初回画面描画時、レポート一覧更新時共通）
  // 引数が'make'=最初の画面描画時のみ画面上に描画するレポートが確定済or未確定を判別して後続処理を行う
  doGetReportList(option: string) {
    this.getReportListUrl = `${environment.apiUrl}/report_list/${this.selectedSite.value}`;
    this.httpClient
      .get(this.getReportListUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetReportListResponse) => {
          this.reportList = [];
          let confirmedUser = null;
          const reportListData = response.result.report_list;
          for (const oneReportData of reportListData) {
            const reportMonth = Number(oneReportData.report_month);
            let fixedAt = null;
            let editedAt = null;
            if (oneReportData.confirmed_report_created_at) {
              fixedAt = String(
                formatDate(
                  oneReportData.confirmed_report_created_at,
                  "yyyy-MM-dd HH:mm:ss",
                  this.locale
                )
              );
            }
            if (oneReportData.value_confirmation_created_at) {
              editedAt = String(
                formatDate(
                  oneReportData.value_confirmation_created_at,
                  "yyyy-MM-dd HH:mm:ss",
                  this.locale
                )
              );
            }
            if (oneReportData.value_confirmation_user_name) {
              confirmedUser = oneReportData.value_confirmation_user_name;
            } else {
              confirmedUser = "削除済みユーザー";
            }
            const reportInfo = new ReportListInfo(
              reportMonth,
              fixedAt,
              editedAt,
              confirmedUser
            );
            if (
              oneReportData.confirmed_report_created_at &&
              oneReportData.value_confirmation_created_at
            ) {
              if (
                formatDate(
                  oneReportData.confirmed_report_created_at,
                  "yyyy-MM-dd HH:mm:ss",
                  this.locale
                ) <
                formatDate(
                  oneReportData.value_confirmation_created_at,
                  "yyyy-MM-dd HH:mm:ss",
                  this.locale
                )
              ) {
                reportInfo.isConfirmedAll = false;
              }
            }
            this.reportList.push(reportInfo);
          }

          this.createJobBatch(null);
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("レポート一覧が取得できませんでした。");
            this.getReportDataFinished = true;
            this.selectedSite.enable();
            this.selectedType.enable();
            this.reportData = [];
            this.reportDataBackUp = [];
          }
        }
      );
  }

  // 選択月とレポート一覧を引数に、選択月に確定済レポートがあるかどうか確認する
  checkAlreadyConfirmed(selectedMonth: number, reportList: ReportListInfo[]) {
    for (const reportInfo of reportList) {
      if (reportInfo.month === selectedMonth) {
        if (!reportInfo.fixedTime) {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  /**
   * データ作成処理の一連
   * - 引数は判断要素各種
   * - ここをレポートデータ作成の基点とする
   * @param dialogResult(any): レポート年月選択ダイアログの結果
   */
  private async createJobBatch(dialogResult: any) {
    this.getReportDataFinished = false;
    this.reportData = [];
    this.reportDataBackUp = [];
    this.selectedSite.disable();
    this.selectedType.disable();

    try{

      // レポートの年月が未設定の場合、レポートリストの先頭を取得
      if (this.selectedMonth == undefined){
        this.selectedMonth = this.reportList[0].month;
      }

      await this.judgeAndRunCreateJob(dialogResult);

    } catch(error) {
      console.error(error);

      this.reportData = [];
      this.reportDataBackUp = [];

      throw error;
    } finally {
      this.getReportDataFinished = true;
      this.selectedSite.enable();
      this.selectedType.enable();
    }
  }

  /**
   * 確定レポートデータか未確定レポートデータかを判定し、実行する処理
   * @param dialogResult(any): レポート年月選択ダイアログの結果
   */
  private async judgeAndRunCreateJob(dialogResult: any){
    // レポートの年月がシステム日付で今月＆新規・変更画面の場合は未確定
    const today = new Date();
    if (this.selectedMonth.toString() == formatDate(today, "yyyyMM", this.locale) && !this.isConfirmedReport) {
      await this.doGetReportData();
      return;
    }

    try {
      // 確定済みレポートデータ取得APIからデータを取得
      this.getReportConfirmedDataResponse = await this.getConfirmedReportData();
    } catch(error) {
      console.error(error);
      if (error.status == "404") {
        // 確定済みレポートがない場合は未確定データで作成する
        await this.doGetReportData();
        return;
      } else if (error.status == "401") {
        alert("セッションが切れています。再度ログインしてください。");
        this.onClickLogout();
      } else {
        alert("レポート情報が取得できませんでした。");
        this.selectedSite.enable();
        this.selectedType.enable();
        this.reportData = [];
        this.reportDataBackUp = [];
        throw error;
      }
    }

    // レポート年月選択ダイアログの結果がある場合はそこから判定
    if (dialogResult != null && dialogResult != undefined) {
      // 確定フラグがあれば確定データから作成
      if (dialogResult.isConfirmed) {
        this.createConfirmedReportData();
      } else {
        // ダイアログから受け取った要素のうち、レポートの作成日時があれば確定済みのデータを取得
        if (
          dialogResult.time !== "" &&
          dialogResult.time !== undefined &&
          dialogResult.time !== null
        ) {
          this.createConfirmedReportData();
        } else {
          await this.doGetReportData();
        }
      }
      return;
    }

    // 全てすり抜けた場合は確定済みレポートから取得
    this.createConfirmedReportData();

  }

  /**
   * レポートリストから選択した年月の前月の確定日を取得
   * @returns 前月の確定日: String
   */
  private getLastMonthConfirmDate(): String {
    // 選択した年月の前月の日付型変数を用意
    // JSやTSのDateのMonth部分は月ではなく、月に該当するIndex（0始まり）なので−1した後に−1をsetする
    let lastMonthDate = new Date(Number(this.selectedMonth.toString().substring(0, 4)), Number(this.selectedMonth.toString().substring(4)) - 1, 1);
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

    // 初期値は選択した年月の前月月初にする
    let lastMonthConfirmDate = formatDate(lastMonthDate, "yyyyMMdd", this.locale);

    if (this.reportList.length > 1) {
      const lastMonthReportInfo = this.reportList.find(
        element => element.month.toString() == formatDate(lastMonthDate, "yyyyMM", this.locale)
      );

      if (lastMonthReportInfo){
        lastMonthConfirmDate =
          lastMonthReportInfo.fixedTime == null ||
          lastMonthReportInfo.fixedTime == undefined
          ? lastMonthConfirmDate
          : formatDate(lastMonthReportInfo.fixedTime, "yyyyMMdd", this.locale);
      }
    }

    return lastMonthConfirmDate;
  }

  /*
    確定済みレポート処理　START
  */

  /**
   * 確定レポートデータ作成処理
   */
  async createConfirmedReportData() {

    // データ取得元のフラグを切り替える
    this.isConfirmedDataSorce = true;

    // 初期化
    this.reportData = [];
    this.reportDataBackUp = [];
    this.selectedSite.disable();
    this.selectedType.disable();

    try {
      // APIから取得したデータを取得
      const confirmedReportFloors = this.getReportConfirmedDataResponse.result.confirmed_report.floors;

      // レポートデータ作成
      confirmedReportFloors.forEach((floor) => {
        floor.partitions.forEach((partition) => {
          partition.partitions_tenants.forEach((partitionsTenant) => {

            // テナント情報がなければスキップ
            if (!partitionsTenant.tenant) {
              return;
            }

            partition.meters.forEach((meter) => {
              const row = new ReportRow();
              row.id = meter.id;
              row.partition = partition.name;
              // 確定の場合、テナント名はtenant_logから取得
              if (partitionsTenant.tenant_log){
                row.name = partitionsTenant.tenant_log.name;
              } else {
                row.name = partitionsTenant.tenant.name;
              }
              row.tenantId = partitionsTenant.tenant.id;
              row.label = meter.label;
              row.info = meter.name.substring(0, meter.name.indexOf("_"));
              row.decimal = meter.decimal_point_position;
              row.type = meter.name.substring(meter.name.indexOf("_") + 1, meter.name.length);

              // 今月確定値
              row.thisMonth = this.getValueConfirmationForConfirmed(row.tenantId, meter);

              // 前月確定値
              row.lastMonth = this.getLastMonthValueConfirmationForConfirmed(row.tenantId, meter);

              // 前月と今月の値が揃っている場合のみ差分を計算する
              if (row.lastMonth !== "N/A" && row.thisMonth !== "N/A") {
                row.used = this.calcUsedValue(
                  row.lastMonth,
                  row.thisMonth,
                  meter.max_value,
                  meter.decimal_point_position
                );

                // 検針値比較ダイアログ用
                // ２ヶ月前の値があれば先月使用量の計算&アラートフラグの更新処理
                if (meter.two_months_before_value_detections.length !== 0) {
                  for (const twoMonthsBeforeValueDetection of meter.two_months_before_value_detections) {
                    if (twoMonthsBeforeValueDetection.tenant_id === row.tenantId) {
                      if (twoMonthsBeforeValueDetection.value_confirmation) {
                        row.lastUsed = this.calcUsedValue(
                          twoMonthsBeforeValueDetection.value_confirmation[
                            "confirmed_value"
                          ],
                          row.lastMonth,
                          meter.max_value,
                          meter.decimal_point_position
                        ).toString();
                        row.alert = this.judgingAlertValue(
                          row.used,
                          row.lastMonth,
                          twoMonthsBeforeValueDetection.value_confirmation[
                            "confirmed_value"
                          ],
                          meter.max_value,
                          meter.decimal_point_position,
                          0.5
                        );
                        break;
                      }
                    }
                  }
                }
              } else {
                row.used = "N/A";
              }

              this.checkAndPushToReportData(
                row,
                partition.report_type,
                String(this.selectedType.value)
              );
            });
          });
        });
      });

      this.selectedSite.enable();
      this.selectedType.enable();
    } catch (error) {
      console.error(error);
      if (error.error.code == "AccountLockError") {
        alert("アカウントがロックされました。管理者までお問合せください");
        this.onClickLogout();
      } else if (error.status == "401") {
        alert("セッションが切れています。再度ログインしてください。");
        this.onClickLogout();
      } else {
        alert("レポート情報が取得できませんでした。");
        this.selectedSite.enable();
        this.selectedType.enable();
        this.reportData = [];
        this.reportDataBackUp = [];
      }
    }
  }

  /**
   * 確定済みデータ取得用APIのコール
   * @returns APIレスポンス: GetReportConfirmedDataResponse
   */
  async getConfirmedReportData(): Promise<GetReportConfirmedDataResponse> {

    // レポートリストから前月の確定日を取得
    const lastMonthConfirmDate = this.getLastMonthConfirmDate();

    const url = `${environment.apiUrl}/report_histories/${this.selectedSite.value}/${this.selectedMonth}/report_details/${lastMonthConfirmDate}`;

    try {
      const response = await this.httpClient
        .get<GetReportConfirmedDataResponse>(url, {
          headers: new HttpHeaders({
            Authorization: this.idToken,
          }),
        })
        .toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * 確定済み
   * 今月確定値を取得
   * @param tenantId(string): テナントID
   * @param meter(ReportMeters): メーター
   * @returns 今月確定値: string
   */
  private getValueConfirmationForConfirmed(tenantId: string, meter: ReportMeters): string {
    let result = "N/A";

    // value_detectionsがない場合は値なしで返却
    if (meter.value_detections.length == 0){
      return result;
    }

    // 確定値は降順でソート
    const valueDetections = meter.value_detections;
    valueDetections.sort((a, b) => Number(new Date(b.created_at)) - Number(new Date(a.created_at)));

    valueDetections.some(detection => {
      if (detection.tenant_id == tenantId && detection.value_confirmation){
        result = detection.value_confirmation.confirmed_value;
        return true;
      } else {
        return false;
      }
    });

    return result;
  }

  /**
   * 確定済み
   * 前月確定値を取得
   * @param tenantId(string): テナントID
   * @param meter(ReportMeters): メーター
   * @returns 前月確定値: string
   */
  private getLastMonthValueConfirmationForConfirmed(tenantId: string, meter: ReportMeters): string {
    let result = "N/A";

    // last_month_value_detectionsがない場合は値なしで返却
    if (meter.last_month_value_detections.length == 0){
      return result;
    }

    // 確定値は降順でソート
    const lastMonthValueDetections = meter.last_month_value_detections;
    lastMonthValueDetections.sort((a, b) => Number(new Date(b.created_at)) - Number(new Date(a.created_at)));

    lastMonthValueDetections.some(detection => {
      if (detection.tenant_id == tenantId && detection.value_confirmation){
        result = detection.value_confirmation.confirmed_value;
        return true;
      } else {
        return false;
      }
    });

    return result;
  }

  /*
    確定済みレポート処理　END
  */

  /*
    未確定レポート処理　START
  */

  // 【未確定】APIからデータ取得〜レポートデータ作成処理呼び出しまで
  // 月変更後はここを呼び出し
  async doGetReportData() {
    // データ取得元のフラグを切り替える
    this.isConfirmedDataSorce = false;

    this.isAlreadyConfirmed = this.checkAlreadyConfirmed(
      this.selectedMonth,
      this.reportList
    );

    // レポートリストから前月の確定日を取得
    const lastMonthConfirmDate = this.getLastMonthConfirmDate();

    try {
      // APIコール
      const url = `${environment.apiUrl}/sites/${this.selectedSite.value}/partitions/meters/${this.selectedMonth}/value_confirmations/${lastMonthConfirmDate}`;
      const response = await this.httpClient
        .get<GetReportDataResponse>(url, {
          headers: new HttpHeaders({
            Authorization: this.idToken,
          }),
        })
        .toPromise();

      let partitions = [];
      response.result.site.floors.forEach(floor => {
        floor.partitions.forEach(parititon => {
          partitions.push(parititon);
        });
      });
      this.reportPartitions = partitions;

      this.createReportDataAll(this.reportPartitions);

    } catch (error) {
      console.error(error);
      if (error.error.code == "AccountLockError") {
        alert("アカウントがロックされました。管理者までお問合せください");
        this.onClickLogout();
      } else {
        alert("レポート情報が取得できませんでした。");
      }
    }
  }

  // 【未確定】レポート作成処理（全体包括）
  createReportDataAll(partitions: ReportPartitions[]) {
    for (const partition of partitions) {
      this.createReportRow(partition);
    }
  }

  // 【未確定】１区画分のデータ作成〜reportDataへの格納まで
  // テナント入替or特殊メーター（倉庫など）がある場合は別処理へ
  // 画面表示用の配列へのpushは全てここで行う
  createReportRow(partition: ReportPartitions) {
    // テナント入替区画（個別処理へ）
    if (
      partition.is_card_display !== 0 &&
      partition.partitions_tenants.length > 1
    ) {
      const rowsWithChange = this.createReportRowWithChanged(partition);
      for (const rowData of rowsWithChange) {
        this.checkAndPushToReportData(
          rowData,
          partition.report_type,
          String(this.selectedType.value)
        );
      }
      return;
    } else {
      // 通常の場合
      if (partition.partitions_tenants.length > 0) {
        for (const meter of partition.meters) {
          const row = new ReportRow();
          row.partition = partition.name;

          // テナント未契約（テナント解約済みも含む）
          if (partition.partitions_tenants.length === 0) {
            row.name = "テナント未契約";
            // 非表示テナントとしての処理
          } else {
            row.name = partition.partitions_tenants[0].tenant.name;
            row.tenantId = partition.partitions_tenants[0].tenant.id;
            row.label = meter.label;
            row.info = meter.name.substr(0, meter.name.indexOf("_"));
            row.decimal = meter.decimal_point_position;
            row.type = meter.name.substr(meter.name.indexOf("_") + 1, 2);
            this.writeMetersDataToRow(meter, row, row.tenantId);
          }

          this.checkAndPushToReportData(
            row,
            partition.report_type,
            String(this.selectedType.value)
          );
        }
      }
    }
  }

  // 【未確定】区画のレポート種別をみて格納先を判別〜格納処理まで行う
  checkAndPushToReportData(row: ReportRow, reportType: number, filter: String) {
    if (filter === "すべて") {
      this.reportData.push(row);
    } else {
      this.reportDataBackUp.push(row);
      if (row.type === filter) {
        this.reportData.push(row);
      }
    }
  }

  // 【未確定】テナント入替区画の行データ作成
  // 必要行数分rowsSamePartitionを生成して
  createReportRowWithChanged(partition: ReportPartitions): any {
    let rows: ReportRow[] = [];

    partition.partitions_tenants.forEach(partitionsTenant => {
      partition.meters.forEach(meter => {
        // メーターの削除フラグがあり、last_month_value_detectionsがない場合はスキップ
        if (
          meter.is_deleted == "0" ||
          (meter.is_deleted == "1" &&
            meter.last_month_value_detections.length > 0)
        ) {
          const row = new ReportRow();
          row.partition = partition.name;
          row.name = partitionsTenant.tenant.name;
          row.label = meter.label;
          row.info = meter.name.substr(0, meter.name.indexOf("_"));
          row.type = meter.name.substr(meter.name.indexOf("_") + 1, 2);
          row.decimal = meter.decimal_point_position;
          row.tenantId = partitionsTenant.tenant.id;
          this.writeMetersDataToRow(meter, row, row.tenantId);
          this.createTenantReplaceReport(meter, partition, partition.partitions_tenants, row);
          rows.push(row);
        }
      });
    });
    return rows;
  }

  /**
   * テナント入れ替えがある場合の各種確定値
   * - 当月確定値
   *  1. メーターのclosed_valueがあればそちらを優先
   *  2. 上記がなく、テナントのclosed_valueがあればそちらを優先
   *  3. いずれも無ければvalue_confirmationが存在するvalue_detectionを検索し、そのIDを取得する
   *  4. どれも無ければNull
   *
   * - 前月確定値
   *  1. 自分が旧テナントでない & 旧テナントを持っている：テナント登録時の初期値
   *  2. 1の条件に当てはまるが、テナント初期値がない（テナントの区画拡張）：旧テナントの当月確定値（=旧テナントの解約時の値）
   *  3. 上記以外：前回確定値
   *  4. 前回確定値がない場合：メーターの初期値
   *
   * - どれも該当しない場合というのはこの処理を呼び出す前のwriteMetersDataToRowで実施済みの前提
   */
  createTenantReplaceReport(meter: ReportMeters, partition: ReportPartitions, partitionTenants: ReportPartitionsTenant[], row: ReportRow) {

    // フラグ
    const isLamp = meter.name === "電灯" || meter.name.substring(meter.name.indexOf("_") + 1, meter.name.length) === "電灯";
    const isPower = meter.name === "動力" || meter.name.substring(meter.name.indexOf("_") + 1, meter.name.length) === "動力";
    const isWaterService = meter.name === "水道" || meter.name.substring(meter.name.indexOf("_") + 1, meter.name.length) === "水道";
    const isGus = meter.name === "ガス" || meter.name.substring(meter.name.indexOf("_") + 1, meter.name.length) === "ガス";

    const isOldTenant = this.isOldTenant(row.tenantId, partition.partitions_tenants);
    const hasOldTenant = this.hasOldTenant(row.tenantId, partition.partitions_tenants);

    // テナント登録時のメーター値を取得
    const valueAtTenantRegistration = this.getMeterValueAtTenantRegistration(row.tenantId, meter, partition.partitions_tenants);

    // テナントの解約時のメーター値を取得
    const closed_value = this.getMeterValueAtTenantClosed(row.tenantId, meter, partition.partitions_tenants);

    const meterClosedValue = meter.closed_value;

    // 旧テナントの当月確定値
    const oldTenantId = this.getOldTenantId(row.tenantId, partitionTenants);
    const oldTenantValue = this.getMeterValueAtTenantClosed(oldTenantId, meter, partition.partitions_tenants);

    if (isLamp || isPower || isWaterService || isGus) {
      // 当月確定値
      if (meterClosedValue != null && meterClosedValue != ""){
        row.thisMonth = meterClosedValue;
      } else if (closed_value != "") {
        row.thisMonth = closed_value;
      }

      // 前月確定値
      if (!isOldTenant && hasOldTenant) {
        // テナント初期値があればそれを優先
        if (valueAtTenantRegistration != ""){
          row.lastMonth = valueAtTenantRegistration;

          // メーターの解約値があればそれが旧テナントの当月確定値なのでそれを採用
        } else if (meterClosedValue != null && meterClosedValue != "") {
          row.lastMonth = meterClosedValue;

          // 上記までに当てはまらない場合は旧テナントの解約時の値が当月確定値なのでそれを採用
        } else if (oldTenantValue != "") {
          row.lastMonth = oldTenantValue;
        }
      }

      row.used = this.calcUsedValue(
        row.lastMonth,
        row.thisMonth,
        row.maxVal,
        row.decimal
      );
    }
  }

  // 【未確定】通常メーターのみ検知して行データに書き込みを行う(電灯動力水道ガスで共通)
  writeMetersDataToRow(meter: ReportMeters, row: ReportRow, tenantId: string) {

    const isLamp = meter.name === "電灯" || meter.name.substring(meter.name.indexOf("_") + 1, meter.name.length) === "電灯"
    const isPower = meter.name === "動力" || meter.name.substring(meter.name.indexOf("_") + 1, meter.name.length) === "動力"
    const isWaterService = meter.name === "水道" || meter.name.substring(meter.name.indexOf("_") + 1, meter.name.length) === "水道"
    const isGus = meter.name === "ガス" || meter.name.substring(meter.name.indexOf("_") + 1, meter.name.length) === "ガス"

    if (isLamp || isPower || isWaterService || isGus) {
      // メーター記入状況の初期化
      row.id = meter.id;
      row.lastMonth = "N/A";
      row.used = "N/A";
      row.thisMonth = "N/A";
      row.maxVal = meter.max_value;
      if (meter.last_month_value_detections.length !== 0) {
        // メーターが存在してかつ検針済み(value_detection配列が空じゃない)のとき、最初の要素から順にvalue_detectionをチェックする
        // メーターIDが引数のものと一致かつvalue_confirmationがnull出ない（＝確定値入力済み）の場合、書き込みを行う
        // １度書き込みを行ったらfor文は抜ける（created_atが最新のもの＝書き込み対象データが先の要素として出てきているはずのため）
        for (const lastValueDetection of meter.last_month_value_detections) {
          if (lastValueDetection.tenant_id === tenantId) {
            if (lastValueDetection.value_confirmation) {
              row.lastMonth = lastValueDetection.value_confirmation["confirmed_value"];
              break;
            }
          }
        }
      }
      if (
        row.lastMonth === "N/A" &&
        meter.initial_value !== undefined &&
        meter.initial_value !== null
      ) {
        row.lastMonth = meter.initial_value;
      }
      if (meter.value_detections.length !== 0) {
        // 引数として連携したテナントIDに一致するときだけ書き込みを行う
        for (const valueDetection of meter.value_detections) {
          if (valueDetection.tenant_id === tenantId) {
            if (valueDetection.value_confirmation) {
              row.thisMonth = valueDetection.value_confirmation["confirmed_value"];
              break;
            }
          }
        }
      }
      if (meter.closed_value !== undefined && meter.closed_value !== null) {
        row.thisMonth = meter.closed_value;
      }
      if (row.lastMonth !== "N/A" && row.thisMonth !== "N/A") {
        // 前月と今月の値が揃っている場合のみ差分を計算する
        row.used = this.calcUsedValue(
          row.lastMonth,
          row.thisMonth,
          meter.max_value,
          meter.decimal_point_position
        );
        // 検針値比較ダイアログ用
        // ２ヶ月前の値があれば先月使用量の計算&アラートフラグの更新処理
        if (meter.two_months_before_value_detections.length !== 0) {
          for (const twoMonthsBeforeValueDetection of meter.two_months_before_value_detections) {
            if (twoMonthsBeforeValueDetection.tenant_id === tenantId) {
              if (twoMonthsBeforeValueDetection.value_confirmation) {
                row.lastUsed = this.calcUsedValue(
                  twoMonthsBeforeValueDetection.value_confirmation[
                    "confirmed_value"
                  ],
                  row.lastMonth,
                  meter.max_value,
                  meter.decimal_point_position
                );
                row.alert = this.judgingAlertValue(
                  row.used,
                  row.lastMonth,
                  twoMonthsBeforeValueDetection.value_confirmation[
                    "confirmed_value"
                  ],
                  meter.max_value,
                  meter.decimal_point_position,
                  0.5
                );
                break;
              }
            }
          }
        }
      }
    }
  }

  /**
   * テナント登録時のメーター初期値を取得
   * @param tenantId(string): テナントID
   * @param meters(ReportMeters): メーター
   * @param partitionsTenants(ReportPartitionsTenant[]): API結果のpartitions_tenants以下
   * @returns 取得した初期値
   */
  private getMeterValueAtTenantRegistration(tenantId: string, meter: ReportMeters, partitionsTenants: ReportPartitionsTenant[]): string {
    let result = "";

    partitionsTenants.some(partitionsTenant => {
      if (partitionsTenant.tenant && partitionsTenant.tenant.initial_value && tenantId == partitionsTenant.tenant.id) {
        // JSONにパース
        const initialValues = JSON.parse(partitionsTenant.tenant.initial_value);
        const initialTenantMeterInfo = initialValues.find(value => value.meterLabel == meter.label);

        // 上記で見つかったらそのメーター値を返却値とする
        if (initialTenantMeterInfo) {
          result = initialTenantMeterInfo.meterValue;
          return true;
        } else {
          return false;
        }
      }
    });

    return result;
  }

  /**
   * テナント解約時のメーター値を取得
   * @param tenantId(string): テナントID
   * @param meters(ReportMeters): メーター
   * @param partitionsTenants(ReportPartitionsTenant[]): API結果のpartitions_tenants以下
   * @returns 取得した解約時の値
   */
  private getMeterValueAtTenantClosed(tenantId: string, meter: ReportMeters, partitionsTenants: ReportPartitionsTenant[]): string {
    let result = "";

    partitionsTenants.some(partitionsTenant => {
      if (partitionsTenant.tenant && partitionsTenant.tenant.closed_value && tenantId == partitionsTenant.tenant.id) {
        // JSONにパース
        const closedValues = JSON.parse(partitionsTenant.tenant.closed_value);
        const closedTenantMeterInfo = closedValues.find(value => value.meterLabel == meter.label);

        // 上記で見つかったらそのメーター値を返却値とする
        if (closedTenantMeterInfo) {
          result = closedTenantMeterInfo.meterValue;
          return true;
        } else {
          return false;
        }
      }
    });

    return result;
  }

  /**
   * 自分が旧テナントかどうかの判定
   * @param currentTenantId(string): 確認対象のテナントID
   * @param partitionsTenants(ReportPartitionsTenant[]): 元データのpartitions_tenants
   * @returns 旧テナントかどうか: boolean
   */
  private isOldTenant(currentTenantId: string, partitionsTenants: ReportPartitionsTenant[]): boolean{

    // テナントが1つしかない場合
    if (partitionsTenants.length == 0){
      return false;
    }

    // 作成日時の昇順でソート
    // tenantsの作成日時だと、区画拡張の時に狂うので、partitions_tenantsでソートする
    partitionsTenants.sort(
      (a, b) => Number(new Date(a.contracted_at)) - Number(new Date(b.contracted_at))
    );

    // tenantだけを抽出
    let tenants: ReportTenant[] = [];
    partitionsTenants.forEach(partitionsTenant => {
      tenants.push(partitionsTenant.tenant);
    });

    // 現在のテナントIDのインデックスを特定
    const currentTenantIndex = tenants.findIndex(tenant => tenant.id == currentTenantId);

    // テナントの数−1であれば（インデックスが最大であれば）false、それ以外はtrue
    return currentTenantIndex != tenants.length - 1;
  }

  /**
   * 自分が旧テナントを持っているか
   * @param currentTenantId(string): 確認対象のテナントID
   * @param partitionsTenants(ReportPartitionsTenant[]): 元データのpartitions_tenants
   * @returns 旧テナントを持っているか: boolean
   */
  private hasOldTenant(currentTenantId: string, partitionsTenants: ReportPartitionsTenant[]): boolean{

    // テナントが1つしかない場合
    if (partitionsTenants.length == 0){
      return false;
    }

    // 作成日時の昇順でソート
    // tenantsの作成日時だと、区画拡張の時に狂うので、partitions_tenantsでソートする
    partitionsTenants.sort(
      (a, b) => Number(new Date(a.contracted_at)) - Number(new Date(b.contracted_at))
    );

    // tenantだけを抽出
    let tenants: ReportTenant[] = [];
    partitionsTenants.forEach(partitionsTenant => {
      tenants.push(partitionsTenant.tenant);
    });

    // 現在のテナントIDのインデックスを特定
    const currentTenantIndex = tenants.findIndex(tenant => tenant.id == currentTenantId);

    // インデックスが0（一番古いデータ）でなければtrue
    return currentTenantIndex != 0;
  }

  /**
   * 旧テナントIDを特定する
   * @param currentTenantId(string): 確認対象のテナントID
   * @param partitionsTenants(ReportPartitionsTenant[]): 元データのpartitions_tenants
   * @returns 旧テナントID: string
   * - 旧テナントがない、自分が旧テナントな場合などは空文字
   */
  private getOldTenantId(currentTenantId: string, partitionsTenants: ReportPartitionsTenant[]):string {

    // テナントが1つしかない場合
    if (partitionsTenants.length == 0){
      return "";
    }

    // 作成日時の昇順でソート
    // tenantsの作成日時だと、区画拡張の時に狂うので、partitions_tenantsでソートする
    partitionsTenants.sort(
      (a, b) => Number(new Date(a.contracted_at)) - Number(new Date(b.contracted_at))
    );

    // tenantだけを抽出
    let tenants: ReportTenant[] = [];
    partitionsTenants.forEach(partitionsTenant => {
      tenants.push(partitionsTenant.tenant);
    });

    // 現在のテナントIDのインデックスを特定
    const currentTenantIndex = tenants.findIndex(tenant => tenant.id == currentTenantId);

    // 現在のテナントIDのインデックス-1のテナントIDを返す
    if (currentTenantIndex == 0) {
      return "";
    } else {
      return tenants[currentTenantIndex - 1].id;
    }

  }

  /*
    未確定レポート処理　END
  */

  // カード表示に戻る処理
  onClickCardList() {
    this.router.navigate(["meter-card-list"]);
  }

  // レポート確定ボタン押下時の処理
  onClickReportConfirm() {
    const dialogRef = this.dialog.open(ReportConfirmDialogComponent, {
      width: "600px",
      data: this.isAlreadyConfirmed,
    });

    // レポートリストから前月の確定日を取得

    // 選択した年月の前月の日付型変数を用意
    // JSやTSのDateのMonth部分は月ではなく、月に該当するIndex（0始まり）なので−1した後に−1をsetする
    let lastMonthDate = new Date(Number(this.selectedMonth.toString().substring(0, 4)), Number(this.selectedMonth.toString().substring(4)) - 1, 1);
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

    // 初期値は選択した年月の前月月初にする
    let lastMonthConfirmDate = formatDate(lastMonthDate, "yyyyMMdd", this.locale);

    if (this.reportList.length > 1) {
      const lastMonthReportInfo = this.reportList.find(
        element => element.month.toString() == formatDate(lastMonthDate, "yyyyMM", this.locale)
      );

      if (lastMonthReportInfo){
        lastMonthConfirmDate =
          lastMonthReportInfo.fixedTime == null ||
          lastMonthReportInfo.fixedTime == undefined
          ? lastMonthConfirmDate
          : formatDate(lastMonthReportInfo.fixedTime, "yyyyMMdd", this.locale);
      }
    }


    dialogRef.afterClosed().subscribe((result) => {
      if (result === "Confirm") {
        this.getReportDataFinished = false;
        this.selectedSite.disable();
        this.selectedType.disable();
        this.postConfirmedReportUrl = `${environment.apiUrl}/report_histories/report_details`;
        let params = new HttpParams();

        params = params.set("site_id", Const.site_id);
        params = params.set("report_month", this.selectedMonth.toString());
        params = params.set("last_month_confirm_date_str", lastMonthConfirmDate);
        params = params.set("is_confirmed", this.isConfirmedDataSorce);
        this.httpClient
          .post(this.postConfirmedReportUrl, params, {
            headers: new HttpHeaders({
              Authorization: this.idToken,
            }),
          })
          .subscribe(
            (response) => {
              this.isConfirmedReport = true;  // 確定レポート固定

              this.doGetReportList("reload");
              this.createConfirmedReportData();
              const messageInfo = new MessageInfo(
                "レポートの確定が完了しました。確定レポートを表示します。",
                ""
              );
              const messageDialogRef = this.dialog.open(
                MessageDialogComponent,
                {
                  width: "500px",
                  data: messageInfo,
                }
              );
            },
            (err) => {
              if (err.error.code == "AccountLockError") {
                alert(
                  "アカウントがロックされました。管理者までお問合せください"
                );
                this.onClickLogout();
              } else {
                alert("レポートが確定できませんでした。");
              }
            }
          );
      }
    });
  }

  //指定した小数点まで切り捨てる
  confirmedValueFormat(val: string, point: number) {
    let formatedNum = "";
    if (val !== "*" && val !== "N/A") {
      let ten = new BigNumber(Math.pow(10, point));
      let tmpNum = new BigNumber(val);
      let result = tmpNum.times(ten);
      result = new BigNumber(Math.floor(Number(result)));
      let endRes = result.dividedBy(ten);
      formatedNum = endRes.toString();
    } else {
      formatedNum = val;
    }
    return formatedNum;
  }

  // レポート一覧をクリックされた時の処理
  onClickReportList() {
    const dialogRef = this.dialog.open(ReportListDialogComponent, {
      width: "1150px",
      data: this.reportList,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedMonth = result.month;
        // レポート一覧で押下されたボタンによって確定済or未確定データの表示を分ける
        this.selectedType = new UntypedFormControl("すべて");
        this.isConfirmedReport = result.isConfirmed;

        // データ作成処理
        this.createJobBatch(result);
      }
    });
  }

  // 当月検針値（の横のカメラ）をクリックした時の処理
  onClickThisMonth(
    partition: string,
    tenantName: string,
    meterId: string,
    checkedMonth: string,
    tenantId: string,
    label: string
  ) {
    this.getOneMeterDataUrl = `${environment.apiUrl}/meters/${meterId}/tenants/${tenantId}/${checkedMonth}/value_detections`;
    this.httpClient
      .get(this.getOneMeterDataUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetInSiteMeterResponse) => {
          this.meter = response.result.meter;
          const correctDialogData = new CorrectDialogData(
            partition,
            tenantName,
            this.meter,
            this.loginUser,
            this.canEdit,
            "report",
            tenantId,
            checkedMonth,
            label
          );
          const dialogRef = this.dialog.open(ValueCorrectDialogComponent, {
            width: "400px",
            data: correctDialogData,
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result === "dialog close") {
              this.doGetReportList("reload");
              // データ作成処理
              this.createJobBatch(null);
            }
          });
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("メーター情報が取得できませんでした。");
          }
        }
      );
  }

  // 当月使用量をクリックした時の処理
  // 引数からダイアログ表示用データを生成して、ダイアログ表示コンポーネントに連携する
  onClickUsed(
    meterType: string,
    partition: string,
    name: string,
    info: string,
    decimal: number,
    thisMonthUsed: string,
    lastMonthUsed: string,
    alertFlag: number
  ) {
    let rateOfChangeStr: string;
    if (!lastMonthUsed) {
      lastMonthUsed = "N/A";
      rateOfChangeStr = "N/A";
    } else {
      let tmUsed = new BigNumber(thisMonthUsed);
      let lmUsed = new BigNumber(lastMonthUsed);

      const rateOfChangeTemp = tmUsed.minus(lmUsed);
      let rateOfChange = rateOfChangeTemp.dividedBy(lmUsed);
      rateOfChange = rateOfChange.times(100);

      rateOfChangeStr = this.rateChangeStr(Number(rateOfChange));
    }
    const compareDialogData = new CompareDialogData(
      partition,
      name,
      info,
      decimal,
      meterType,
      thisMonthUsed,
      lastMonthUsed,
      rateOfChangeStr,
      alertFlag
    );
    const dialogRef = this.dialog.open(ValueCompareDialogComponent, {
      width: "400px",
      data: compareDialogData,
    });
  }

  // CSVでのレポート出力処理（1クリックで２種類のレポート同時ダウンロード）
  onClickCSV() {
    let tmpReportData: ReportRow[];
    if (String(this.selectedType.value) !== "すべて") {
      tmpReportData = this.reportDataBackUp;
    } else {
      tmpReportData = this.reportData;
    }
    // 電灯・動力・水道、ガスのレポートCSV生成〜ダウンロード
    let csvData =
      "区画,テナント名称,メーター名称,メーター番号,メーター種類,前月確定値,当月確定値,当月使用量\r\n";
    for (const row of tmpReportData) {
      if (!row.info) {
        row.info = "";
      }
      if (!row.type) {
        row.type = "";
      }
      const rowData =
        row.partition.replace(",", "、") +
        ", " +
        row.name.replace(",", "、") +
        ", " +
        row.info +
        ", " +
        row.label +
        ", " +
        row.type +
        ", " +
        this.confirmedValueFormat(row.lastMonth, row.decimal) +
        ", " +
        this.confirmedValueFormat(row.thisMonth, row.decimal) +
        ", " +
        this.confirmedValueFormat(row.used, row.decimal) +
        "\r\n";
      const rowDataFormated = rowData.replace(/\*/g, "").replace(/N\/A/g, "");
      csvData = csvData + rowDataFormated;
    }
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csvData], { type: "csv/plain" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    const fileName = String(this.selectedMonth) + "kenshin-report.csv";
    link.download = fileName;
    link.click();

    // ガスのレポートCSV生成〜ダウンロード
    /*    let gasCsvData =
      "コード,系統名称,,メーター番号,前月確定値(ガス),当月確定値(ガス),当月使用量(ガス)\r\n";
    for (const row of this.gasReportData) {
      if (!row.info) {
        row.info = "";
      }
      const rowData =
        row.partition.replace(",", "、") +
        ", " +
        row.name.replace(",", "、") +
        ", " +
        row.info +
        ", " +
        row.label +
        ", " +
        row.gasLastMonth +
        ", " +
        row.gasThisMonth +
        ", " +
        row.gasUsed +
        "\r\n";*/
    //   const rowDataFormated = rowData.replace(/\*/g, "").replace(/N\/A/g, "");
    /*   gasCsvData = gasCsvData + rowDataFormated;
    }
      const gasBlob = new Blob([bom, gasCsvData], { type: "csv/plain" });
      const gasLink = document.createElement("a");
      gasLink.href = window.URL.createObjectURL(gasBlob);
      const gasFileName = String(this.selectedMonth) + "kenshin-gas-report.csv";
      gasLink.download = gasFileName;
      gasLink.click();*/
  }

  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;

    this.httpClient
      .get(this.getLoginUserUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetLoginUserResponse) => {
          Const.loginUser = response.result.login_user;
          Const.mjitUser = Number(Const.loginUser.user_type) === 0;
          Const.adminUser = Number(Const.loginUser.user_type) === 1;
          if (Const.mjitUser) {
            Const.site_id = localStorage.getItem("siteId");
          } else {
            for (const site of response.result.login_user.sites) {
              if (site.id === localStorage.getItem("siteId")) {
                Const.site_id = site.id;
              }
            }
          }

          if (Const.site_id === null) {
            alert("前回選択した施設が見つかりませんでした。");
            Const.site_id = response.result.login_user.sites[0].id;
          }

          this.doSetValue();
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
          } else {
            alert(
              "ログイン情報が取得できませんでした。再度ログインしてください。"
            );
          }
          this.onClickLogout();
        }
      );
  }

  // ログアウト処理
  onClickLogout() {
    Const.release();
    this.auth.signOut();
  }

  // 「yyyymm」形式の月情報を受け取って「yyyy年mm月」形式に変換する処理
  monthStringFormat(yearMonth: number) {
    const formatedMonth =
      String(yearMonth).substr(0, 4) +
      "年" +
      String(yearMonth).substr(4) +
      "月";
    return formatedMonth;
  }

  // 使用量比較画面で表示する時にに正負と％を補正
  // 変化0の時と、倍率無限の時の補正
  rateChangeStr(rate: number) {
    if (rate > 0 && rate !== Infinity) {
      return "+" + String(this.rounding(rate)) + "%";
    } else if (!rate) {
      const zero = "0%";
      return zero;
    } else if (rate < 0) {
      return String(this.rounding(rate)) + "%";
    } else {
      const infinity = "∞%";
      return infinity;
    }
  }

  // 小数点第３位で四捨五入かつ小数点第２位までは必ず表示するよう補正する処理
  rounding(val: number) {
    const returnNumber = Number(val).toFixed(2);
    return returnNumber;
  }

  roundingUsed(val: number, decimal: number) {
    const returnNumber = Number(val).toFixed(decimal);
    return returnNumber;
  }

  usedValueFormat(val: number, point: number): Number {
    let ten = new BigNumber(Math.pow(10, point));
    let tmpNum = new BigNumber(val);
    let result = tmpNum.times(ten);
    result = new BigNumber(Math.floor(Number(result)));
    let endRes = result.dividedBy(ten);
    return Number(endRes);
  }

  // メーター１周を考慮しつつ、当月使用量を計算する処理
  calcUsedValue(
    lastMonthValue: any,
    thisMonthValue: any,
    maxValue: string,
    decimalPoint: number
  ) {
    if (
      lastMonthValue !== "N/A" &&
      lastMonthValue !== "*" &&
      thisMonthValue !== "N/A" &&
      thisMonthValue !== "*"
    ) {
      // 今月の検針値が先月の検針値より小さい場合、メーター最大値を足して返す
      if (Number(lastMonthValue) > Number(thisMonthValue)) {
        let tempThisMonth = this.usedValueFormat(thisMonthValue, decimalPoint);
        let tempLastMonth = this.usedValueFormat(lastMonthValue, decimalPoint);
        let tempMaxV = this.usedValueFormat(Number(maxValue), decimalPoint);

        let thisMonth = new BigNumber(Number(tempThisMonth));
        let lastMonth = new BigNumber(Number(tempLastMonth));
        let maxV = new BigNumber(Number(tempMaxV));

        let usedValue = maxV.minus(lastMonth).plus(thisMonth);

        let endUsedValue = this.usedValueFormat(
          Number(usedValue),
          decimalPoint
        );
        return endUsedValue.toString();
      } else {
        let tempThisMonth = this.usedValueFormat(thisMonthValue, decimalPoint);
        let tempLastMonth = this.usedValueFormat(lastMonthValue, decimalPoint);

        let thisMonth = new BigNumber(Number(tempThisMonth));
        let lastMonth = new BigNumber(Number(tempLastMonth));

        let usedValue = thisMonth.minus(lastMonth);

        let endUsedValue = this.usedValueFormat(
          Number(usedValue),
          decimalPoint
        );

        return endUsedValue.toString();
      }
    } else {
      return "N/A";
    }
  }

  // 前月使用量（前月検針値-前々月検針値）と当月使用量（当月検針値-前月検針値）を比較して、異常基準値の割合（今回は50％）以上増減している場合フラグ変更
  // 過減少は1、過増加は2
  // 当月使用量（計算済）、前月検針値、前々月検針値、メーター最大値、異常基準値を引数にとる
  judgingAlertValue(
    thisMonthUsed: string,
    lastMonthValue: string,
    twoMonthsBeforeValue: string,
    maxValue: string,
    decimal: number,
    judgementValue: number
  ) {
    const lastMonthUsed = this.calcUsedValue(
      twoMonthsBeforeValue,
      lastMonthValue,
      maxValue,
      decimal
    );

    if (lastMonthUsed === "N/A") {
      const diff = Number(thisMonthUsed) - Number(lastMonthUsed);
      let diffPer = diff / Number(lastMonthUsed);
      if (diffPer < 0) {
        diffPer = diffPer * -1;
        if (diffPer >= judgementValue) {
          return 1;
        }
      } else {
        if (diffPer >= judgementValue) {
          return 2;
        }
      }
      return 0;
    } else {
      let tmUsed = new BigNumber(thisMonthUsed);
      let lmUsed = new BigNumber(Number(lastMonthUsed));

      const diff = new BigNumber(tmUsed.minus(lmUsed));
      let diffPer = new BigNumber(diff.dividedBy(lmUsed));

      if (Number(diffPer) < 0) {
        diffPer = diffPer.times(-1);
        if (Number(diffPer) >= judgementValue) {
          return 1;
        }
      } else {
        if (Number(diffPer) >= judgementValue) {
          return 2;
        }
      }
      return 0;
    }
  }

  ngOnDestroy() {}
  loadMeterData() {
    this.expMeterData = [];
    const url = `${environment.apiUrl}/sites/${this.selectedSite.value}/meters_info_all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          let jsonObj = JSON.parse(JSON.stringify(res));
          if (jsonObj.status == 200) {
            let jsonFloors = jsonObj.result.site.floors;
            let site_id = jsonObj.result.site.id;
            this.getPartitionTenantData();
            for (let index = 0; index < jsonFloors.length; index++) {
              const floor = jsonFloors[index];
              const partitions = floor.partitions;
              for (let index = 0; index < partitions.length; index++) {
                const partition = partitions[index];
                for (let index = 0; index < partition.meters.length; index++) {
                  const meterObj = partition.meters[index];
                  let meterModel = meterObj as MeterInfo;

                  meterModel.partName = partition.name;
                  if (
                    partition.partitions_tenants !== undefined &&
                    partition.partitions_tenants.length > 0
                  ) {
                    meterModel.tenantName =
                      partition.partitions_tenants[0].tenant_log.name;
                    meterModel.site_id = site_id;
                    meterModel.floor_id = floor.id;
                    meterModel.floor_name = floor.name;
                  }
                  if (
                    Number(this.loginUser.user_type) !== this.userTypeSysAdmin
                  ) {
                    if (
                      meterModel.expiration_date !== null &&
                      meterModel.expiration_date !== undefined &&
                      meterModel.expiration_date !== ""
                    ) {
                      var day = new Date();
                      day.setMonth(day.getMonth() + 24);
                      const dayOfNextYear = formatDate(
                        day,
                        "yyyyMMdd",
                        this.locale
                      );
                      const expiration = formatDate(
                        meterModel.expiration_date,
                        "yyyyMMdd",
                        this.locale
                      );
                      if (expiration < dayOfNextYear) {
                        meterModel.isExpireMeterFlag = true;

                        let expMeterModel = meterObj as MeterInfo;
                        expMeterModel = meterModel;
                        this.expMeterData.push(expMeterModel);
                      }
                    }
                  }
                }
              }
            }
            // ソート
            if (this.expMeterData.length > 0) {
              this.expMeterData.sort((n1, n2) => {
                if (n1.expiration_date > n2.expiration_date) {
                  return 1;
                }
                if (n1.expiration_date < n2.expiration_date) {
                  return -1;
                }
                return 0;
              });
            }
          }
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          }
        }
      );
  }
  getPartitionTenantData() {
    const dateData = new Date();
    const thisMonth = formatDate(dateData, "yyyyMM", this.locale);
    const url = `${environment.apiUrl}/sites/${this.selectedSite.value}/partitions/tenants/${thisMonth}`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetTenantsResponse) => {
          this.partitionTenants = response.result.site.partitions;
          this.partitionTenants = this.partitionTenants.filter(
            (item) => item.partitions_tenants.length != 0
          );
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          }
        }
      );
  }
}
